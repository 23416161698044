/* ====================  IMPORT  ==================== */

// BOOTSTRAP
@import 'bootstrap';

// OWL CAROUSEL
@import '../../../node_modules/owl.carousel/src/scss/owl.carousel';
@import '../../../node_modules/owl.carousel/src/scss/owl.theme.default';



/* ====================  CUSTOM  ==================== */


@include media-breakpoint-up(md) {
	html {
		font-size: 1.125rem;
	}
}

body {
	position: relative;
}

.bg-cover {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.navbar#header-nav {
	transition: $transition-base;
	&.header-dark {
		background-color: rgba(0, 0, 0, .9);
	}
	.navbar-brand {
		img {
			max-width: 150px;
			@include media-breakpoint-up(md) {
				max-width: 250px;
			}
		}
	}
}

.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .show > .nav-link {
	@include media-breakpoint-up(xl) {
		border-bottom: 1px solid #FFF;
	}
}

section {
	&#hero {
		background-image: linear-gradient(rgba(52,55,64,0.3), rgba(52,55,64,0.3)), url('/assets/img/hero-bg-s.jpg');
		@include media-breakpoint-up(lg) {
			background-image: linear-gradient(rgba(52,55,64,0.3), rgba(52,55,64,0.3)), url('/assets/img/hero-bg-m.jpg');
		}
		@include media-breakpoint-up(xxl) {
			background-image: linear-gradient(rgba(52,55,64,0.3), rgba(52,55,64,0.3)), url('/assets/img/hero-bg.jpg');
		}
		> .container {
			padding-top: 120px;
		}
	}
	&#cta {
		background-image: linear-gradient(rgba(52,55,64,0.7), rgba(52,55,64,0.7)), url('/assets/img/cta-bg-s.jpg');
		@include media-breakpoint-up(lg) {
			background-image: linear-gradient(rgba(52,55,64,0.7), rgba(52,55,64,0.7)), url('/assets/img/cta-bg-m.jpg');
		}
		@include media-breakpoint-up(xxl) {
			background-image: linear-gradient(rgba(52,55,64,0.7), rgba(52,55,64,0.7)), url('/assets/img/cta-bg.jpg');
		}
	}
}

.btn {
	text-transform: uppercase;
	&-primary,
	&-outline-primary {
		&:hover,
		&:active {
			color: #FFF;
		}
	}
	&-primary {
		color: #FFF;
		&:focus {
			color: #FFF;
		}
	}
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
	background-color: shade-color($primary, 15%);
	border-color: shade-color($primary, 20%);
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
	background-color: shade-color($primary, 20%);
	border-color: shade-color($primary, 20%);
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary:hover  {
	background-color: shade-color($primary, 15%);
	border-color: shade-color($primary, 20%);
}

.img-circle-b {
	height: 8rem;
	width: 8rem;
	border: 12px solid #FFF;
	border-radius: 50%;
	object-fit: cover;
}

.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 2rem;
}

.program {
	.more-accordion {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		text-decoration: none;
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
		h4,
		.h4,
		h5,
		.h5,
		h6,
		.h6 {
			color: $body-color;
		}
		&[aria-expanded="false"] .ico-accordion::after {
			content: '\002B';
			font-size: 2rem;
			font-weight: 700;
			font-family: 'Lato';
			@include media-breakpoint-up(md) {
				padding-left: 2rem;
			}
		}
		&[aria-expanded="true"] .ico-accordion::after {
			content: '\2212';
			font-size: 2rem;
			font-weight: 700;
			font-family: 'Lato';
			@include media-breakpoint-up(md) {
				padding-left: 2rem;
			}
		}
	}
}


.card-speaker {
	background-image: linear-gradient(#FFFFFF 25%, #F6F7FD 25%);
	border: 0;
}

.ratio-1x1 {
	@include media-breakpoint-up(md) {
		--bs-aspect-ratio: calc(3 / 4 * 100%);
	}
	@include media-breakpoint-up(lg) {
		--bs-aspect-ratio: calc(9 / 21 * 100%);
	}
}

// Back to top

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	border-radius: 3px;
	border: 1px solid #FFF;
	background: $primary;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	z-index: 1;
	display: none;
	#top-arrow {
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}


// Animations

@keyframes swing {
	0% {
		opacity: 0;
	}
	20% {
		transform: rotate3d(0, 0, 1, 5deg);
	}
	40% {
		transform: rotate3d(0, 0, 1, -4deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 2deg);
	}
	80% {
		transform: rotate3d(0, 0, 1, -2deg);
	}
	100% {
		opacity: 1;
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@keyframes rollin {
	0% { 
		opacity: 0; 
		transform: translateX(-70%) rotate(-25deg); 
	}
	100% { 
		opacity: 1; 
		transform: translateX(0px) rotate(0deg); 
	}
}

@keyframes grow {
	0% {
		transform: scale(0.1);
	}
	100% {
		transform: scale(1);
	}
}

[class^='animate-'], 
[class*=' animate-'] {
	opacity: 0;
	animation-duration: 1s;
	animation-fill-mode: both;
}

[class^='animated-'], 
[class*=' animated-'] {
	opacity: 1;
	animation-duration: 1s;
	animation-fill-mode: both;
}

.animated {
	&-swing {
		animation-name: swing;
	}
	&-rollin {
		animation-name: rollin;
	}
	&-grow {
		animation-name: grow;
	}
}