/* ======= BOOTSTRAP (5) ======= */

$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Lato', sans-serif;
$btn-font-family: 'Lato', sans-serif;
$primary: #FF00CC;
$secondary: #F6F7FD;
$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 3.5),
	7: ($spacer * 4),
	8: ($spacer * 5),
	9: ($spacer * 6)
	), $spacers);
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3.4;
$h2-font-size: $font-size-base * 2.1;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.35;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base * 1.1;
$navbar-dark-color: #FFF;
$navbar-dark-hover-color: rgba(#FFF, .75);
$navbar-dark-active-color: #FFF;
$card-border-color: transparent;
$card-spacer-y: $spacer * 2;
$card-spacer-x: $spacer * 2;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$box-shadow-sm: 0 .1rem .35rem rgba(0, 0, 0, .04);
$box-shadow: 0 .4rem .75rem rgba(0, 0, 0, .07);
$box-shadow-lg: 0 .8rem 2rem rgba(0, 0, 0, .1);
$figure-caption-font-size: .775rem;



@import '../../../node_modules/bootstrap/scss/bootstrap';